import React from 'react';
import {
  CloseButton,
  Correct,
  HelpHeader,
  HelpPanel,
  Incorrect,
  ModalFade,
  NotInWord,
} from './help.style';
import { MdClose } from 'react-icons/md';

interface Props {
  onDismiss: () => void;
}

const Help = ({ onDismiss }: Props) => {
  return (
    <ModalFade onClick={onDismiss}>
      <CloseButton>
        <MdClose size="2.5em" />
      </CloseButton>
      <HelpPanel onClick={(e) => e.stopPropagation()}>
        <HelpHeader>How to play WOODLE</HelpHeader>
        Guess a 5-letter word, and we'll tell you how many of its letters are
        <Correct>correctly positioned</Correct> and how many of the letters are
        <Incorrect>incorrectly positioned</Incorrect> as a pair of numbers next to the guess. Make a
        sequence of these guesses to figure out which letters go where, until you've guessed the
        word of the day.
        <br />
        <br />
        You can tap on the letters in the guess history to change their colour and keep track of
        which letters <strong>you believe</strong> are <Correct>correctly positioned</Correct>,
        <Incorrect>incorrectly positioned</Incorrect> or{' '}
        <NotInWord>not in the word at all.</NotInWord>
      </HelpPanel>
    </ModalFade>
  );
};

export default Help;
